import "@arco-design/web-react/es/Notification/style";
import _Notification from "@arco-design/web-react/es/Notification";
import "@arco-design/web-react/es/Message/style";
import _Message from "@arco-design/web-react/es/Message";
import axios from 'axios'; // 请求

const http = axios.create({
  baseURL: "",
  timeout: 36000
}); // 请求拦截

http.interceptors.request.use(config => {
  //请求头设置
  let token = localStorage.getItem('member_token') || '';
  config.headers.Authorization = token;
  return config;
}, err => {
  console.log(err);
}); // 响应拦截

http.interceptors.response.use(response => {
  return response.data;
}, error => {
  if (error === undefined || error.code === 'ECONNABORTED') {
    _Message.warning('服务请求超时');

    return Promise.reject(error);
  }

  const {
    response: {
      status,
      statusText,
      data: {
        msg = '服务器发生错误'
      }
    }
  } = error;
  const {
    response
  } = error;
  const info = response.data;

  if (status == 401) {
    _Notification.error({
      title: '登录超时',
      content: '正在前往登录页面'
    });

    localStorage.clear();
    window.location.href = '/login';
  }

  if (status == 404 || status == 504) {
    _Notification.error({
      title: '错误',
      content: '服务器发生错误'
    });
  }

  if (status == 500) {
    _Notification.error({
      title: '服务器发生错误',
      content: info.message
    });
  }

  console.log(response);
  console.log('info.status:' + info.status);
  console.log('status:' + status);
  return response.data;
  return Promise.reject(error); // const config = err.config
  // console.log(config.response);
}); //history.push(currentRoute.path ? currentRoute.path : `/${key}`);

function request({
  method = "get",
  url,
  data = {},
  params = {}
}) {
  return http({
    method,
    url,
    data,
    params
  });
}

export default request;
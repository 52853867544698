/*
 * @Author: your name
 * @Date: 2022-04-03 23:07:43
 * @LastEditTime: 2023-04-01 00:14:30
 * @LastEditors: DESKTOP-B7BA493\zc 45184529@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \luopan_v2_branch\src\services\v2.ts
 */
import request from '@/utils/http';
const Api = {
  //登录
  'login': 'auth/login',
  'quick': 'auth/quick_admin',
  //------------------会员--------------
  //会员信息
  'member_info': 'member/detail',
  //资金日志
  'logs_money': 'member/logs_money',
  //会员权限
  'member_permissions': 'member/permissions',
  //获取支付金额
  'pay_num': 'tool/pay_num',
  //微信支付
  'pay_code': 'tool/pay_code',
  //支付状态
  'pay_status': 'tool/pay_status',
  //------------------首页--------------
  //首页订单日志
  'index_order_logs': 'work/order_logs',
  //订单统计
  'index_order_count': 'work/order_count',
  //------------------版权--------------
  //作品列表
  'work_index': 'work/index',
  //作品类型
  'work_type_select': 'work/type',
  //作品详情
  'work_info': 'work/info',
  //订单日志
  'status_log': 'work/status_log',
  //添加订单
  'work_order_add': 'work/save',
  //编辑订单
  'work_order_update': 'work/update',
  //更新作品状态
  'work_status': 'work/status',
  //下载
  'down_cert': 'work/down_cert',
  //------------------权利人------------
  //权利人列表
  'work_author': 'author/index',
  //添加权利人
  'work_author_add': 'author/save',
  //获得权利人
  'work_author_info': 'author/index',
  //修改权利人
  'work_author_update': 'author/update',
  //删除权利人
  'work_author_delete': 'author/delete',
  //------------------其他--------------
  //获得地址
  'get_area': 'tool/area_tree',
  //oss
  'get_oss': 'tool/oss',
  //身份证识别
  'get_card_info': 'tool/card_info',
  //营业执照识别
  'get_business_info': 'tool/business_info',
  //------------------签字--------------
  //
  'work_sign_setkey': 'tool/work_sign_setkey',
  //
  'work_sign_getkey': 'tool/work_sign_getkey',
  //
  'work_sign_setcode': 'worksign/work_sign_setcode',
  //
  'work_sign_iskey': 'worksign/work_sign_iskey'
};
export async function v1Request({
  api,
  method,
  data = {},
  params = {},
  suffix = ''
}) {
  const _url = '/web/api/v1/' + Api[api] + suffix;

  return new Promise(resolve => {
    request({
      url: _url,
      method: method,
      params: params,
      data: data
    }).then(res => {
      resolve(res);
    });
  });
}
const useRoute = () => {
  return [{
    name: '首页',
    key: 'member',
    icon: 'icon-yunyingguanli',
    level: 'one',
    children: [{
      name: '快捷中心',
      key: 'member/index'
    }]
  }, {
    name: '版权管理',
    key: 'copyright',
    icon: 'icon-dingdanguanli',
    children: [{
      name: '版权登记',
      key: 'member/work/register',
      ignore: true
    }, {
      name: '我的登记',
      key: 'member/work/order'
    }, {
      name: '证书下载',
      key: 'member/work/certificate'
    }, {
      name: '权利人管理',
      key: 'member/work/author'
    }]
  }, {
    name: '会员中心',
    key: 'user',
    icon: 'icon-wodegerenxinxi',
    children: [{
      name: '会员信息',
      key: 'member/detail/info'
    }, {
      name: '我的钱包',
      key: 'member/wallet/recharge'
    }, {
      name: '资金明细',
      key: 'member/wallet/log'
    }]
  }];
};

export default useRoute;